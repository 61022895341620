<template>
  <div style="background: #06085a;">

    <div class="home">
      <!-- <div @click="onSelect(9)">asdasdasdas</div> -->
      <van-tabs @click="set">
        <van-tab v-for="(item,idx) in list" :key="idx" :title="item.itemName">
          <!-- <div class="mian">
            <div class="bz">步骤1</div>
          </div> -->

        </van-tab>
      </van-tabs>
      <div style="overflow: hidden;">
        <van-steps active-color="#79fff6" inactive-color="#fff" direction="vertical" :active="acv" id="ul">
          <van-step v-for="(items,is) in maintainListDOS" :class="mtid == items.id && itemId == xmform.itemId&&ssisok == true?'ssuo':''" :key="is" :id="'page'+items.id">
            <h3>步骤{{is+1}}</h3>
            <p>{{items.title}}</p>
            <div class="des">{{ items.des }}</div>
            <div class="flx">
              <div v-for="(it,index) in items.orderMaintainDOS" :key="index">
                <img class="imgs" @click="toimg(items.orderMaintainDOS,index)" v-if="it.type == 0" :src="it.details" alt="">
                <video class="boot_video" muted="muted" v-else :src="it.details" type="video/mp4" controls="controls" loop="-1">
                  <p>你的浏览器不支持video标签.</p>
                </video>
              </div>
            </div>
          </van-step>

        </van-steps>
      </div>

    </div>

  </div>
</template>

<script>
import Speech from "speak-tts";
import keyBoard from "@/components/vant-number-plate/vnp-keyboard";
import { itemdetails } from "../../api/check";
export default {
  components: {
    keyBoard,
  },
  data() {
    return {
      show: false,
      showtime: false,
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 0, 31),
      idx: 0,
      acv: 0,
      type: 1,
      list: [],
      maintainListDOS: [],
      path: "wss://garage.car-posthouse.cn/api/vehicle/ws/", //正式
      // path: "ws://47.96.248.209:12345/ws", //测试
      websock: null, //建立的连接
      websockvideo: null,
      // charId: s.uuid(),//随机生成的UUID
      lockReconnect: false, //是否真正建立连接
      timeout: 6 * 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      socket: "",
      mtid: null,
      itemId: null,
      carNo: null,
      ssisok: false,
      xmform: {},
      oid: "",
    };
  },
  methods: {
    //------------------------------------
    WebSocketinit: function () {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        var path = this.path + "USER:" + this.oid;

        // 实例化socket
        this.websock = new WebSocket(path);
        // 监听socket连接
        this.websock.onopen = this.open;
        // 监听socket错误信息
        this.websock.onerror = this.error;
        // 监听socket消息
        this.websock.onmessage = this.getMessage;
        // 监听socket关闭
        this.websock.onclose = this.close;
      }
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.WebSocketinit();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      // console.log(self.websock)
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，

        if (self.websock.readyState == 1) {
          //如果连接正常
          self.websock.send("heartCheck");
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
    open: function () {
      let data = {
        id: this.numberid,
      };
      console.log(JSON.stringify(data));
      this.websock.send(JSON.stringify(data));
    },
    error: function () {
      //连接失败事件
      //错误
      console.log("WebSocket连接发生错误");
      //错误提示
      // s.error("Websocket error, Check you internet!")
      //重连
      this.reconnect();
    },
    send: function () {
      //发送消息
    },
    close: function () {
      //连接关闭事件
      //关闭
      // console.log("connection closed (" + e.code + ")");
      //提示关闭
      // s.error("连接已关闭",3);
      //重连
      this.reconnect();
    },

    getMessage: function (msg) {
      var _this = this;
      _this.reset();

      if (msg.data != "1") {
        var data = JSON.parse(msg.data);
        if (data.flag != 10) {
          console.log(data);
          console.log(_this.carNo);
          // _this.eadernew(data.ask);

          if (data.carNo == _this.carNo) {
            _this.$toast("接收新进度");
            _this.onLoad();
            _this.ssisok = true;

            _this.mtid = Number(data.mtid);
            _this.itemId = Number(data.itemId);
            _this.jscarNo = Number(data.carNo);

            _this.onSelect(data.mtid);
            setTimeout(() => {
              // 方法区
              _this.ssisok = false;
            }, 6000);
            _this.speak(data.ask);
          }
        }
      }
    },
    // ---------------------------------------------------------
    toimg(item, idx) {
      console.log(img);
      var img = [];
      for (var i in item) {
        if(item[i].type == 0){
          img.push(item[i].details);
        }
       
      }
      // return
      this.$router.push({
        name: "showimg",
        query: {
          img: img.join(","),
          idx: idx,
        },
      });
    },
    onSelect(mtid) {
      if (document.getElementById("page" + mtid)) {
        var PageId = document.getElementById("page" + mtid);
        console.log(document.body.scrollTop);
        window.scrollTo({
          top: PageId.offsetTop - 70,
          behavior: "smooth",
        });
      }
    },
    getjp(val) {
      console.log(val);
      var _this = this;
      _this.form.carNo = val;
    },
    set(e) {
      this.onSelect(5);
      this.maintainListDOS = [];
      if (this.list[e].maintainVO) {
        if (this.list[e].maintainVO.maintainListDOS) {
          this.maintainListDOS = this.list[e].maintainVO.maintainListDOS;
        }
      }
    },
    //选择日期
    formatDate(date) {
      var n = date.getFullYear();
      var y = date.getMonth() + 1;
      var r = date.getDate();
      if (y < 10) {
        y = "0" + y;
      }
      if (r < 10) {
        r = "0" + r;
      }
      return `${date.getFullYear()}-${y}-${r}`;
    },
    onConfirm(date) {
      this.showtime = false;
      if (this.type == 1) {
        this.form.jqxExpire = this.formatDate(date);
      } else {
        this.form.syxExpire = this.formatDate(date);
      }

      console.log(this.date);
    },
    setTime(type) {
      this.type = type;
      this.showtime = true;
    },

    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中...",
      // });
      itemdetails({ oid: this.oid }).then((e) => {
        // loading.clear();

        this.list = e.data;
        this.xmform = e.data[this.idx];
        console.log(this.xmform);
        console.log(this.idx);
        if (e.data[this.idx].maintainVO) {
          if (e.data[this.idx].maintainVO.maintainListDOS) {
            var maintainListDOS = e.data[this.idx].maintainVO.maintainListDOS;
            this.maintainListDOS = maintainListDOS;
            for (var i = 0; i < maintainListDOS.length; i++) {
              if (maintainListDOS[i].success == true) {
                this.acv = i + 1;
                console.log(i);
                return;
              }
            }
          } else {
            this.maintainListDOS = [];
          }
        } else {
          this.maintainListDOS = [];
        }
      });
    },
    appSource() {
      var _this = this;
      var count_down = 0;
      var dataTime = new Date(+new Date() + 8 * 3600 * 1000)
        .toJSON()
        .substr(0, 19)
        .replace("T", " ");
      const u = navigator.userAgent,
        app = navigator.appVersion;
      //const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        count_down = this.datedifference(_this.time, dataTime, 1);

        console.log("ios");
      } else {
        count_down = this.datedifference(_this.time, dataTime, 2);
        console.log("andriod");
      }
      return count_down;
    },
    datedifference(sDate1, sDate2, type) {
      //sDate1和sDate2是2006-12-18格式
      var dateSpan, tempDate, iDays;
      if (type == 1) {
        sDate1 = sDate1.replace(/-/g, "/");
        sDate2 = sDate2.replace(/-/g, "/");
      }

      sDate1 = Date.parse(sDate1);
      sDate2 = Date.parse(sDate2);
      dateSpan = sDate2 - sDate1;
      // alert(sDate1);
      // dateSpan = Math.abs(dateSpan);
      // console.log(dateSpan / 1000);
      iDays = dateSpan / 1000;
      // console.log(dateSpan / (24 * 3600 * 1000))
      // iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
      return iDays;
    },
    back() {
      this.$router.go(-1);
    },
    //语音播报
    speak(text) {
      // var text = '驿车驹,测试语言播报，粤SDFGH入场洗车,谢谢惠顾！'
      this.speech.speak({ text: text }).then(() => {
        console.log("播报完成...");
      });
    },
    speechInit() {
      this.speech = new Speech();
      this.speech.setLanguage("zh-CN");
      this.speech.init().then(() => {
        console.log("语音播报初始化完成...");
      });
    },
  },
  created() {
    function getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    }
    var _this = this;
    var search = window.location.search;
    this.search = window.location.href;
    this.oid = getSearchString("oid", search); //结果：18
    this.gid = getSearchString("gid", search); //结果：18
    this.carNo = getSearchString("carNo", search); //结果：18
    var garageName = getSearchString("garageName", search); //结果：18
    document.title = garageName;
    this.onLoad();
    this.WebSocketinit();
  },
  mounted() {
    this.speechInit();
  },
};
</script>

<style lang="less" scoped>
.imgs {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.boot_video {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}
.home {
  background: #06085a;
  // height: 100%;
  .van-tabs {
    background: #06085a;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;
  }
  .bz {
    color: #79fff6;
    font-size: 16px;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
  }
  .des {
    font-size: 14px;
  }
  .flx {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
.ssuo {
  text-transform: uppercase;

  position: relative;
  /* width: 500px; */
  animation: animate 2.5s linear infinite;
  background: #001975;
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
</style>
<style>
body {
  background: #06085a;
}
.van-tabs__content {
  background: #06085a !important;
}
.van-steps {
  background: #06085a !important;
}
.van-tabs__wrap {
  border-bottom: 1px solid #00a2ff;
  color: #fff !important;
}
.van-tabs__nav {
  background: #06085a !important;
  /* box-shadow: inset 0px 0px 12px 1px rgba(0,255,250,0.2); */
  border: 1px solid #00a2ff;
}
.van-tab--active {
  color: #79fff6 !important;
  font-weight: bold;
  font-size: 16px !important;
}
.van-tab {
  color: #dcdcdc !important;
  font-size: 14px;
}
.van-step--finish {
  color: #fff;
}
.van-tabs__line {
  background-color: #79fff6 !important;
}
body {
  background: #06085a;
  height: 100%;
  overflow: visible !important;
}
</style>
